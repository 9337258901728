"use client"

import { Suspense, useEffect, useRef, useState } from "react"
import dynamic from "next/dynamic"
import Image from "next/image"
import Link from "next/link"
import { usePathname, useRouter, useSearchParams } from "next/navigation"
import { UserButton, useUser } from "@clerk/nextjs"
import { Search, Users, X } from "lucide-react"

// Required CSS import, unless you're overriding the styling
import { siteConfig } from "@/config/site"
import { cn } from "@/lib/utils"
import { Button, buttonVariants } from "@/components/ui/button"
import { MainMenu } from "@/components/main-menu"
import { MainNav } from "@/components/main-nav"

import { SafeExit } from "./safe-exit"
import "@knocklabs/react/dist/index.css"
import React from "react"
import { useJerkSession } from "@/jerkClient"

import { Combobox } from "./combobox"
import { identifyUser } from "@/app/actions/knock"

const Over18Prompt = dynamic(() => import("./over18promt"), { ssr: false })
const SignUpChoiceModal = dynamic(() => import("./sign-up-modal"), {
  ssr: false,
})
const KnockUI = React.lazy(() => import("./knock-ui"))

export function SiteHeader() {
  const [showNotifications, setShowNotifications] = useState(false)
  const [searchValue, setSearchValue] = useState("")
  const notifButtonRef = useRef(null)
  const { isSignedIn, user  } = useUser()

  const { session, refreshSession} = useJerkSession()
  const [safeUrl, setSafeUrl] = useState<string | undefined>(undefined)
  const searchParams = useSearchParams()
  const router = useRouter()
  const pathName = usePathname()
  const [showSearchBar, setShowSearchBar] = useState(false)
  const [searchBarFocused, setSearchBarFocused] = useState(false)
  
  const toggleSearchBarFocus = () => setSearchBarFocused(!searchBarFocused)
  const toggleSearchBar = () => setShowSearchBar(!showSearchBar)

  useEffect(()=>{
    if(isSignedIn && !session.clerkUserId) refreshSession()
  },[session, isSignedIn])

  const [showSignUpModal, setShowSignUpModal] = useState(false)

  const refCode = searchParams.get("ref_code")
  useEffect(() => {
    if (refCode) {
      window.localStorage.setItem("ref_code", refCode)
    }
  }, [refCode])
  
  //TODO REVIEW WITH ALEX
  useEffect(() => {
    if (session?.clerkUserId) {
      identifyUser().then(() => null).catch(e => console.error(e))
      setSafeUrl(
        session.activeUserAccount?.safeExitUrl
          ? `https://${session.activeUserAccount?.safeExitUrl}`
          : "https://google.com"
      )
    }
  }, [session.activeUserAccount?.safeExitUrl])

  return (
    <>
      <Over18Prompt />
      <>
        <header className="glowing-box glowing-box-reduced sticky top-0 z-[45] w-full bg-background">
          <div className="relative w-full bg-background">
            <div className="relative flex h-16 w-full items-center bg-background px-2 md:container sm:justify-between sm:space-x-0">
              <MainNav items={siteConfig.mainNav} />
              <div className="py-4 w-full">
                {showSearchBar && (
                  <SearchEscortProfile toggleSearch={toggleSearchBarFocus} />
                )}
              </div>
              <div className="flex min-w-fit flex-1 items-center justify-end space-x-1">
                <nav className="flex items-center space-x-1">
                  {!showSearchBar && isSignedIn && session.activeUserAccountId && (
                    <Suspense fallback={<></>}>
                      <KnockUI
                        userId={`useraccount-${session.activeUserAccountId}`}
                        show={showNotifications}
                        setShow={setShowNotifications}
                        buttonRef={notifButtonRef}
                      />
                    </Suspense>
                  )}
                  {/* {!showSearch && ( */}
                  {!showSearchBar && (
                    <UserButton
                      userProfileMode="navigation"
                      userProfileUrl="/after-login"
                      afterSignOutUrl="/after-login"
                    />
                  )}
                  {!showSearchBar && !isSignedIn && (
                    <>
                      <Button
                        variant="outline"
                        size="sm"
                        className={cn(
                          `b-2 rounded-full border-pink-800 ${buttonVariants({
                            size: "sm",
                            variant: "outline",
                          })}`,
                          { hidden: showSearchBar }
                        )}
                        onClick={() => setShowSignUpModal(true)}
                      >
                        Sign up
                      </Button>
                      <Link href={siteConfig.links.signIn} rel="noreferrer">
                        <div
                          className={cn(
                            buttonVariants({
                              size: "sm",
                              variant: "ghost",
                            }),
                            { hidden: showSearchBar }
                          )}
                        >
                          <span>Log in</span>
                        </div>
                      </Link>
                    </>
                  )}
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={toggleSearchBar}
                  >
                    {showSearchBar ? <X /> : <Search />}
                  </Button>
                  <MainMenu />
                </nav>
              </div>
            </div>
          </div>
          <div className="absolute left-1/2 top-16 -mt-px ml-[-36px]">
            <SafeExit url={safeUrl} />
          </div>
        </header>
        {showSignUpModal && (
          <SignUpChoiceModal
            open={showSignUpModal}
            setOpen={setShowSignUpModal}
          />
        )}
      </>
    </>
  )
}

function SearchEscortProfile({ toggleSearch }: { toggleSearch: () => void }) {
  const router = useRouter()
  return (
    <div className="flex px-2 md:px-4 w-full">
      <Combobox
        noLabel={true}
        onFocus={toggleSearch}
        onBlur={toggleSearch}
        classNames={{
          container:
            "border border-foreground w-full rounded-full jutify-end !p-0 !px-2 md:flex",
          item: "border-foreground", // Add this line to style the input border if needed
          wrapper: "border border-foreground", // Add this line to style the dropdown border if needed
        }}
        label={"Search Escort Name"}
        inlineLabel
        icon={<Users size={18} />}
        onSelect={(e) => {
          const key = Object.keys(e)[0]
          router.push(`/e/${key.split("||")[1]}`)
        }}
        endpoint={"/api/nickname?q="}
        valueKey={"value"}
        labelKey={"label"}

        multiselect={false}
        node={(item) => (
          <div className="flex items-center gap-2">
            {item.props.profilePic && (
              <Image
                src={item.props.profilePic}
                alt={`${item.props.nickname}`}
                width={36}
                height={36}
                className="rounded-full"
              />
            )}
            <div className="flex flex-col">
              <div className="text-primary font-bold">
                {item.props.nickname}
              </div>
            </div>
          </div>
        )}
      />
    </div>
  )
}

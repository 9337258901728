'use client'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { cn } from "@/lib/utils"
import { Skeleton } from "./ui/skeleton"
import { options } from "./faqs"

type TextBlockProps = React.HTMLAttributes<HTMLDivElement> & {
  loading?: boolean
  content?: any
  links?: any
  disableProse?: boolean
}

export const TextBlock = ({
  loading,
  content,
  links,
  className,
  disableProse,
}: TextBlockProps) => {
  if (loading)
    return (
      <div className="grid grid-cols-1 gap-4">
        {Array.from({ length: 5 }).map((_, idx) => (
          <Skeleton key={idx} className="h-8" />
        ))}
      </div>
    )
  if (content)
    return (
      <div
        className={cn(
          {
            "prose dark:prose-invert prose-a:font-bold prose-a:text-foreground max-w-full":
              !disableProse,
          },
          className
        )}
      >
        <div>{documentToReactComponents(content.json, options)}</div>
      </div>
    )
  if (links)
    return (
      <div
        className={cn(
          "prose max-w-full dark:prose-invert prose-a:font-bold prose-a:text-foreground",
          className
        )}
      >
        {links.items.map((item: any, idx: number) => (
          <div key={`text-block-link-${idx}`}>
            {documentToReactComponents(item.content.json, options)}
          </div>
        ))}
      </div>
    )
  return null
}

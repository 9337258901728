"use client"

import * as React from "react"
import { Check, ChevronDown, ChevronUp, ChevronsUpDown, X } from "lucide-react"

import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command"
import { Label } from "@/components/ui/label"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"

type ComboboxProps = {
  items?: { id?: number; value: string; label: string; props?: any }[]
  selectedValues?: Record<string, string>
  onSelect: (values: Record<string, string>) => void
  label: string
  defaultLabel?: string
  icon?: React.ReactElement
  inlineLabel?: boolean
  noLabel?: boolean
  onChange?: (value: string) => void
  multiselect?: boolean
  classNames?: {
    container?: string
    label?: string
  }
  node?: (item: any) => React.ReactNode
  onFocus?: () => void
  onBlur?: () => void
  hideInput?: boolean
}

export function Combobox({
  items,
  selectedValues = {},
  onSelect,
  onChange,
  label,
  icon,
  multiselect,
  defaultLabel,
  noLabel,
  inlineLabel,
  hideInput = false,
  classNames,
  node,
  onFocus,
  onBlur
}: ComboboxProps) {
  const [open, setOpen] = React.useState(false)
  const [values, setValues] = React.useState(selectedValues)
  const stringifiedValues = JSON.stringify(selectedValues)
  const popoverContentRef = React.useRef<HTMLDivElement>(null)

  // Had to add this in here to make fields display on reload
  React.useEffect(() => {
    if (stringifiedValues) {
      setValues(JSON.parse(stringifiedValues))
    }
  }, [stringifiedValues])

  // Scroll event handler
  React.useEffect(() => {
    const handleScroll = () => {
      // Perform your actions or styles here based on the scroll event
    }

    const popoverContent = popoverContentRef.current
    if (popoverContent) {
      popoverContent.addEventListener("scroll", handleScroll)
    }

    return () => {
      if (popoverContent) {
        popoverContent.removeEventListener("scroll", handleScroll)
      }
    }
  }, [])

  const DropdownIcons = () => {
    if (hideInput) {
      return open ? (
        <ChevronUp className="ml-2 size-4 shrink-0 text-foreground" />
      ) : (
        <ChevronDown className="ml-2 size-4 shrink-0 text-foreground" />
      )
    }
    return open ? (
      <X className="ml-2 size-4 shrink-0 text-foreground" />
    ) : (
      <ChevronsUpDown className="ml-2 size-4 shrink-0 text-foreground" />
    )
  }

  const labels = () => {
    if (Object.keys(values).length === 0)
      return inlineLabel ? label : defaultLabel || ``
    const arrLabels = Object.entries(values).map(([_, value]) => value)
    if (arrLabels.length < 3) return arrLabels.join(", ")
    return inlineLabel
      ? `${label}: ${arrLabels.length} selected`
      : `${arrLabels.length} selected`
  }

  // if (hideInput) {
  //   return (
  //     <div className="flex w-full flex-col">
  //       {!inlineLabel && (
  //         <Label className="text-xs font-bold uppercase text-foreground">
  //           {label}
  //         </Label>
  //       )}
  //       <NativeSelect
  //         value={Object.keys(selectedValues)[0]}
  //         className="h-8 bg-transparent text-sm"
  //         onChange={(e) => {
  //           onSelect && onSelect({ [e.target.value]: e.target.value })
  //         }}
  //       >
  //         <option value="">{defaultLabel}</option>
  //         {items &&
  //           items.map((item, idx) => (
  //             <option
  //               id={`${item.id}`}
  //               key={`${idx}-${item.value}`}
  //               value={item.value}
  //             >
  //               {item.label}
  //             </option>
  //           ))}
  //       </NativeSelect>
  //     </div>
  //   )
  // }
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger className="w-full rounded-xl p-2" asChild>
        <div className={`flex w-full flex-col ${classNames?.container}`}>
          {!inlineLabel && !noLabel ? (
            <Label className="text-xs font-bold uppercase text-foreground">
              {label}
            </Label>
          ) : null}
          <Button
            variant="ghost"
            role="combobox"
            aria-expanded={open}
            className="w-full justify-between rounded-lg px-0 text-primary"
          >
            <div className="flex items-center">
              {icon && <span className="mr-2 opacity-50">{icon}</span>}
              <span className={cn("text-left", classNames?.label)}>
                {typeof values == "string" ? values : labels()}
              </span>
            </div>
            <DropdownIcons />
          </Button>
        </div>
      </PopoverTrigger>
      <PopoverContent
        ref={popoverContentRef}
        className="combobox-content max-h-[200px] w-full overflow-auto p-0"
      >
        <Command onFocus={onFocus} onBlur={onBlur}>
          {!hideInput && (
            <>
              <CommandInput
                placeholder={
                  label.toLowerCase().includes("search")
                    ? label
                    : `Search ${label}`
                }
                onValueChange={onChange}
              />
              <CommandEmpty>No value found.</CommandEmpty>
            </>
          )}
          <CommandGroup>
            {items &&
              items.map((item, idx) => {
                return (
                  <CommandItem
                    className="w-full text-primary"
                    key={`${idx}-${item.value}`}
                    value={item.value}
                    onSelect={(currentValue) => {
                      const existingEntry = Object.entries(values).find(
                        ([v, l]) =>
                          "" + v.toLowerCase() ===
                            "" + currentValue.toLowerCase() ||
                          "" + l.toLowerCase() ===
                            "" + currentValue.toLowerCase()
                      )
                      if (existingEntry && existingEntry[0]) {
                        delete values[existingEntry[0]]
                        setValues({ ...values })
                        onSelect({ ...values })
                      } else {
                        if (multiselect) {
                          setValues({ ...values, [item.value]: item.label })
                          onSelect({ ...values, [item.value]: item.label })
                        } else {
                          setValues({ [item.value]: item.label })
                          onSelect({ [item.value]: item.label })
                          setOpen(false)
                        }
                      }
                    }}
                  >
                    {multiselect && (
                      <Check
                        className={cn(
                          "mr-2 size-4",
                          values[item.value] ? "opacity-100" : "opacity-0"
                        )}
                      />
                    )}
                    {node ? (
                      node(item)
                    ) : item.label.includes(">") ? (
                      <div className="flex w-full justify-between">
                        <div className="text-xs">
                          {item.label.split(">")[0].trim()}
                        </div>
                        <div className="text-xs font-bold">
                          {item.label.split(">")[1].trim()}
                        </div>
                      </div>
                    ) : (
                      item.label
                    )}
                  </CommandItem>
                )
              })}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  )
}

import * as React from "react"
import Link from "next/link"
import { NavItem } from "@/types/nav"
import Image from "next/image";
interface MainNavProps {
  items?: NavItem[]
}

export function MainNav({ items }: MainNavProps) {
  return (
    <div className="flex gap-6 md:gap-10 shrink-0">
      <Link href="/" className="hidden items-center md:mt-2 md:flex">
        <Image className="hidden dark:block" src="/logo-dark.svg" alt="Logo" width={200} height={40}/>
        <Image className="dark:hidden" src="/logo-light.svg" alt="Logo" width={200} height={40}/>
      </Link>
      <Link href="/" className="items-center md:hidden">
        <Image className="hidden dark:block" src="/logo-sm-dark.svg" alt="Logo" width={40} height={40}/>
        <Image className="dark:hidden" src="/logo-sm-light.svg" alt="Logo" width={40} height={40}/>
      </Link>
    </div>
  )
}
